import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100')
];

export const server_loads = [4,2,3,9,11,12,14,8];

export const dictionary = {
		"/(app)": [34,[4],[5]],
		"/(admin)/admin": [17,[2]],
		"/(admin)/admin/auctioneers": [~18,[2]],
		"/(admin)/admin/cyberclerk": [~19,[2]],
		"/(admin)/admin/discounts": [~20,[2]],
		"/(admin)/admin/domain-blacklist": [21,[2]],
		"/(admin)/admin/news": [~22,[2]],
		"/(admin)/admin/passports": [~23,[2]],
		"/(admin)/admin/places": [~24,[2]],
		"/(admin)/admin/qdrant": [25,[2]],
		"/(admin)/admin/reports": [26,[2,3]],
		"/(admin)/admin/reports/auto": [27,[2,3]],
		"/(admin)/admin/reports/manual": [28,[2,3]],
		"/(admin)/admin/sales": [~29,[2]],
		"/(admin)/admin/sales/pending-publication": [30,[2]],
		"/(admin)/admin/transporters": [~31,[2]],
		"/(admin)/admin/unpaid": [32,[2]],
		"/(admin)/admin/users": [~33,[2]],
		"/(app)/auctioneer/[id]": [~46,[4],[5]],
		"/(app)/bids/[saleId]/autobidsPrint": [~47,[4],[5]],
		"/(collectivity)/collectivity": [72,[9],[10]],
		"/(collectivity)/collectivity/track/[saleId]": [~84,[9],[10]],
		"/(collectivity)/collectivity/[cpId]/import": [~73,[9],[10]],
		"/(collectivity)/collectivity/[cpId]/lots": [74,[9,11],[10]],
		"/(collectivity)/collectivity/[cpId]/lots/online": [~75,[9,11],[10]],
		"/(collectivity)/collectivity/[cpId]/lots/preparation": [~76,[9,11],[10]],
		"/(collectivity)/collectivity/[cpId]/photo/[saleId]": [77,[9],[10]],
		"/(collectivity)/collectivity/[cpId]/questions": [~78,[9,11],[10]],
		"/(collectivity)/collectivity/[cpId]/registrations": [~79,[9,11],[10]],
		"/(collectivity)/collectivity/[cpId]/results": [~80,[9,11],[10]],
		"/(collectivity)/collectivity/[cpId]/sale": [~81,[9],[10]],
		"/(collectivity)/collectivity/[cpId]/slips": [~82,[9,11],[10]],
		"/(collectivity)/collectivity/[cpId]/stats": [~83,[9,11],[10]],
		"/(app)/document": [48,[4],[5]],
		"/(live)/live": [85,[12],[13]],
		"/(live)/live/[cpId]": [~86,[12],[13]],
		"/(live)/live/[saleId]/commissaire": [87,[12],[13]],
		"/(live)/live/[saleId]/cyberclerc": [88,[12],[13]],
		"/(live)/live/[saleId]/encoder": [~89,[12],[13]],
		"/(live)/live/[saleId]/encoder/tiers": [~90,[12],[13]],
		"/(live)/live/[saleId]/spectateur": [91,[12],[13]],
		"/(app)/login": [49,[4,6],[5]],
		"/(app)/login/forgot-password": [50,[4,6],[5]],
		"/(app)/lot-history": [51,[4],[5]],
		"/(mobile)/mobile": [92,[14]],
		"/(mobile)/mobile/login": [97,[14,15]],
		"/(mobile)/mobile/login/forgot-password": [98,[14,15]],
		"/(mobile)/mobile/theme": [99,[14]],
		"/(mobile)/mobile/[cpId]/sale": [~93,[14]],
		"/(mobile)/mobile/[cpId]/sale/[saleId]/lots/live": [~94,[14]],
		"/(mobile)/mobile/[cpId]/sale/[saleId]/lots/online": [~95,[14]],
		"/(mobile)/mobile/[cpId]/sale/[saleId]/registrations": [~96,[14]],
		"/(payment)/payment/pay/[transferId]": [~100,[16]],
		"/(app)/payment/[id]/unpaid": [52,[4,7],[5]],
		"/(app)/payment/[id]/wu": [~53,[4,7],[5]],
		"/(app)/photo/[saleId]": [54,[4],[5]],
		"/(app)/sale/edit/[id]": [~66,[4],[5]],
		"/(app)/sale/import/[saleId]": [~67,[4],[5]],
		"/(app)/sale/track/[saleId]": [~68,[4],[5]],
		"/(app)/sale/[saleId]/absenteeBids": [~55,[4,8],[5]],
		"/(app)/sale/[saleId]/adjudications": [~56,[4,8],[5]],
		"/(app)/sale/[saleId]/autobids": [~57,[4,8],[5]],
		"/(app)/sale/[saleId]/bids": [~58,[4,8],[5]],
		"/(app)/sale/[saleId]/lots": [~59,[4,8],[5]],
		"/(app)/sale/[saleId]/minutes": [~60,[4,8],[5]],
		"/(app)/sale/[saleId]/questions": [~61,[4,8],[5]],
		"/(app)/sale/[saleId]/registrations": [~62,[4,8],[5]],
		"/(app)/sale/[saleId]/slips": [~63,[4,8],[5]],
		"/(app)/sale/[saleId]/sold": [64,[4,8],[5]],
		"/(app)/sale/[saleId]/stats": [65,[4,8],[5]],
		"/(app)/signup": [69,[4],[5]],
		"/(app)/theme": [70,[4],[5]],
		"/(app)/user": [71,[4],[5]],
		"/(app)/[id]/bidders": [~35,[4],[5]],
		"/(app)/[id]/bills": [~36,[4],[5]],
		"/(app)/[id]/bills/[billNumber]": [37,[4],[5]],
		"/(app)/[id]/home": [~38,[4],[5]],
		"/(app)/[id]/places": [~39,[4],[5]],
		"/(app)/[id]/sales": [~41,[4],[5]],
		"/(app)/[id]/sale/create": [~40,[4],[5]],
		"/(app)/[id]/steps": [~42,[4],[5]],
		"/(app)/[id]/transporters": [~43,[4],[5]],
		"/(app)/[id]/unpaid": [~44,[4],[5]],
		"/(app)/[id]/users": [~45,[4],[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';